@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'assets/scss/variable';
@import '@dpdhl/angular-shared-ui/styles/tailwind';
@import '@dpdhl/angular-shared-ui/styles/fonts';
@import '@dpdhl/angular-shared-ui/styles/date-picker';
@import '@dpdhl/angular-shared-ui/styles/select';
@import '@dpdhl/angular-shared-ui/styles/themes/dpdhl';

html {
  font-size: 16px;

  body {
    @apply text-default;
    @apply font-body;
  }
}

img {
  display: inline;
}

.body1 {
  font-family:
    $font Arial,
    Helvetica,
    sans-serif !important;
}

.font-size-20 {
  font-size: $font-20;
}

.show-pointer {
  cursor: pointer;
}

.amber {
  background-color: $amber;
}

.bold-font {
  font-family: $boldfont;
}

.e-list-item.e-active > .e-text-content .e-list-text {
  color: $grey;
}

.e-dialog.e-draggable .e-dlg-header-content {
  border-radius: 0.5rem !important;
}

.e-popup.e-popup-open.e-dialog {
  border-radius: 0.5rem !important;
}

.e-control .e-dialog .e-lib .e-draggable .e-popup .e-popup-open {
  border-radius: 0.5rem !important;
}

.assign-alert-template-dialog .e-dlg-content {
  border-radius: 0.5rem !important;
}

// SELECT STYLES START
// TODO remove me once selecet is extracted into component
.dhl-select .e-dropdownbase .e-list-item {
  color: $black !important;
}

.dhl-select.e-input-group {
  border-bottom-style: none !important;
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;

  &::before {
    background: none !important;
  }

  &::after {
    background: none !important;
  }
}

.dhl-select.e-input-focus {
  &::before {
    background: none !important;
  }

  &::after {
    background: none !important;
  }
}

.dhl-select-container {
  border: 0.1rem solid $grey-300;
  border-radius: $border-radius;
  padding: 0.5rem 1.2rem;

  label {
    color: $txtgrey;
    font-size: 1.4rem;
  }

  .color-red {
    color: $red;
  }
}

// SELECT STYLES END

// TABLE STYLES START
.e-grid .e-headercelldiv {
  color: $black !important;
  font-family: $font-b !important;
}

// TABLE STYLES END
.infobox-body {
  width: auto !important;

  .infobox-actions {
    height: 2.9rem !important;
    overflow: hidden;
  }
}

.infobox-actions-list li a {
  font-size: 1.3rem;
}

.infobox-close img {
  margin-top: -5.5rem;
}

.black-line {
  background-color: $black;
  height: 0.5rem;
  margin-bottom: 1.5rem;
  width: 5rem;
}

.dashboard-statstics-view .e-panel .e-panel-container .content {
  font-weight: 600;
  height: 10vh;
  line-height: 7.5vh;
  text-align: center;
  vertical-align: middle;
}

.e-panel-content {
  border: 0.05rem solid #e0e0e0 !important;
}

.e-dashboardlayout.e-control .e-panel {
  background: #fff;
  box-shadow: none !important;
  box-sizing: border-box;
  height: 10vh !important;
  position: absolute;
}

.e-contextmenu-wrapper .e-contextmenu .e-menu-item {
  .e-menu-icon {
    height: 12px !important;
    width: 18px !important;
  }

  .view-context {
    content: url('/assets/images/CoMo/view-details.svg');
  }

  .comment-context {
    content: url('/assets/images/CoMo/comments_alert.svg');
  }

  .accept-context {
    content: url('/assets/images/CoMo/accept.svg');
  }

  .resolving-context {
    content: url('/assets/images/CoMo/accept.svg');
  }

  .close-context {
    content: url('/assets/images/CoMo/close_manually.svg');
  }
}

.e-dlg-container .e-dlg-content,
.alert-view,
.alert-content {
  .status-open {
    background: $status-open-border 0 0% no-repeat padding-box;
    border: 0.1rem solid $red;
    border-radius: 0.6rem;
    opacity: 1;
    padding: 0.5rem;
    text-align: center;
  }

  .status-deactivated {
    background: $status-deactivate 0 0% no-repeat padding-box;
    border: 0.1rem solid $status-deactivate-border;
    border-radius: 0.6rem;
    color: $status-deactivate-color;
    opacity: 1;
    padding: 0.5rem;
    text-align: center;
  }

  .status-resolved,
  .status-manuallyclosed {
    background: $status-close 0 0% no-repeat padding-box;
    border: 0.1rem solid $status-close-border;
    border-radius: 0.6rem;
    opacity: 1;
    padding: 0.5rem;
    text-align: center;
  }

  .status-resolving {
    background: $status-resolving 0 0% no-repeat padding-box;
    border: 0.1rem solid $status-resolving-border;
    border-radius: 0.6rem;
    opacity: 1;
    padding: 0.5rem;
    text-align: center;
  }

  .status-accepted {
    background: $status-accepted 0 0% no-repeat padding-box;
    border: 0.1rem solid $status-accepted-border;
    border-radius: 0.6rem;
    opacity: 1;
    padding: 0.5rem;
    text-align: center;
  }

  .context-action {
    cursor: pointer;
  }
}

.e-icon-dlg-close {
  color: #e3165b !important;
}

.device-management-delete-rule-dialog .dpdhl-modal {
  width: 33% !important;
}

.device-management-qrcode-dialog .dpdhl-modal {
  width: 47rem !important;
}

app-alert-template-form .dpdhl-modal {
  max-width: 100% !important;
  width: 96% !important;
}

.graph-loading {
  background-image: url('/assets/images/CoMo/skeleton/line-graph-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.dpdhl-animate-pulse {
    height: 44rem;
    margin-top: 1.5rem;
  }
}

.qrcode-loading {
  background-image: url('/assets/images/CoMo/skeleton/Qr-Code-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.dpdhl-animate-pulse {
    height: 43.5rem;
    margin-top: 1.5rem;
  }
}

.alerts-loading {
  background-image: url('/assets/images/CoMo/skeleton/table-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.dpdhl-animate-pulse {
    height: 47rem;
  }
}

.alerts-container {
  .alerts-loading.dpdhl-animate-pulse {
    height: 40rem;
  }

  .app-card.full-height {
    height: 74vh;
  }
}

.e-qrcode-icon {
  content: url('/assets/images/CoMo/qr-code.svg');
}

.e-toast {
  border-radius: 0.5rem !important;

  .e-toast-progress {
    background-color: $lightamber !important;
  }

  .e-toast-download {
    background-color: $statistics-accepted !important;
    color: $white !important;

    .e-toast-download-icon {
      content: url('/assets/images/CoMo/downloading.gif');
    }
  }

  .e-toast-complete {
    background-color: $green !important;
    color: $white !important;

    .e-toast-complete-icon {
      content: url('/assets/images/CoMo/completed.svg');
    }
  }
}

.e-grid .e-gridcontent .e-emptyrow td {
  color: $red;
  text-align: center;
}

.maps {
  .map {
    height: 50vh;
    min-height: 32rem;
  }
  @apply flex flex-col;

  h2 {
    @apply py-16 pl-16;
  }

  &.detail-list {
    .map {
      min-height: 32rem;
    }
  }
}

.contain-strict {
  contain: strict;
}
